import React from 'react'
import img from './img1.svg'
import img2 from './img2.svg'
import img3 from './img3.svg'

export default function Games() {
  return (
    <div className='flex flex-col items-center justify-center space-y-12 my-8 px-20' id="games">
         <h1 className='text-3xl'>Games</h1>
         <img src={img} alt="game image 1" />
         <img src={img2} alt="game image 1" />
         <img src={img3} alt="game image 1" />
     </div>
  )
}
