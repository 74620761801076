import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import imageUrlBuilder from "@sanity/image-url";
import client from "../../sanityCli";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

export default function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [navbarData, setNavbarData] = useState(null);
  const [pageData, setPageData] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const navbarResult = await client.fetch(
          '*[_type == "navbarContent"][0]'
        );
        setNavbarData(navbarResult);
      } catch (error) {
        console.error("Error fetching navbar data from Sanity:", error);
      }

      try {
        const pageResult = await client.fetch('*[_type == "pageContent"][0]');
        setPageData(pageResult);
      } catch (error) {
        console.error("Error fetching page data from Sanity:", error);
      }
    };

    fetchData();
  }, []);

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const handleNavLinkClick = (sectionId) => {
    // console.log("section", sectionId);
    if (location.pathname !== "/") {
      // Navigate to the home page first
      navigate("/");
      // After navigating to home, scroll to the specified section
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 500); // Adjust the delay time as needed
    } else {
      // Scroll to the specified section with smooth behavior
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
    // Close mobile menu if it's open
    closeMobileMenu();
  };

  return (
    <nav
      id="home-hero"
      className="navbar flex items-center justify-between py-4 md:py-2 lg:py-1 px-6 md:px-10 lg:px-16 2xl:px-24 bg-white shadow-md"
    >
      <div className="navbar-logo">
        {navbarData && (
          <Link to="/">
            <img
              src={urlFor(navbarData.logo)}
              alt="logo-image"
              className="w-44"
            />
          </Link>
        )}
      </div>
      <div className="lg:hidden hamburger-button">
        <button onClick={toggleMobileMenu}>
          {navbarData && <img src={urlFor(navbarData.hamburgerBtn)} />}
        </button>
      </div>
      {isMobileMenuOpen && (
        <div className="mobile-menu lg:hidden absolute top-0 right-0 z-10 bg-white px-4 w-full h-auto shadow-lg">
          <div className="flex flex-col gap-4 pt-6 pb-6 px-2">
            <div className="flex items-center justify-between mb-5">
              {navbarData && (
                <Link to="/">
                  <img
                    src={urlFor(navbarData.logo)}
                    alt="logo-image"
                    className="w-44"
                  />
                </Link>
              )}
              <button
                onClick={toggleMobileMenu}
                className="bg-gray-400 rounded-lg p-2 "
              >
                {navbarData && (
                  <img src={urlFor(navbarData.hamburgerBtn)} className="w-8" />
                )}
              </button>
            </div>
            {/* {navbarData && (
              <p
                onClick={() => handleNavLinkClick("whatWeDo")}
                className="monstrrate text-[#000000] uppercase text-2xl font-medium cursor-pointer"
              >
                {navbarData.whatWeDo}
              </p>
            )}
            {navbarData && (
              <p
                onClick={() => handleNavLinkClick("topGames")}
                className="monstrrate text-[#000000] uppercase text-2xl mt-4 font-medium cursor-pointer"
              >
                {navbarData.topGames}
              </p>
            )} */}

            <div className="flex flex-col items-start gap-5 justify-start">
              {navbarData && (
                <p
                  onClick={() => handleNavLinkClick("about")}
                  className="monstrrate text-[#000000] uppercase text-2xl mt-4 font-medium cursor-pointer"
                >
                  {navbarData.aboutUs}
                </p>
              )}
              {navbarData && (
                <p
                  onClick={() => handleNavLinkClick("games")}
                  className="monstrrate text-[#000000] uppercase text-2xl mt-4 font-medium cursor-pointer"
                >
                  {navbarData.teams}
                </p>
              )}

              {navbarData && pageData && (
                <Link
                  to={`/${pageData.blog ? pageData.blog : "blog"}`}
                  className="monstrrate text-[#000000] uppercase text-2xl mt-4 font-medium cursor-pointer"
                >
                  {navbarData.blog}
                </Link>
              )}
                 {navbarData && (
              <p
                onClick={() => handleNavLinkClick("black-box")}
                className="monstrrate text-[#000000] uppercase w-fit text-2xl mt-4 font-medium p-3 rounded-sm cursor-pointer"
                style={{
                  background:
                    " linear-gradient(180deg, #7CFA73 0%, #21DBA4 100%)",
                }}
              >
                {navbarData.contact}
              </p>
            )}

              {/* {navbarData && (
                <Link
                  to={`/${
                    pageData && pageData.reviews ? pageData.reviews : "reviews"
                  }`}
                  className="monstrrate text-[#000000] uppercase text-2xl mt-4 font-medium cursor-pointer"
                >
                  {navbarData.reviews}
                </Link>
              )} */}
            </div>
         
          </div>
        </div>
      )}

      {/* Desktop Menu */}

      <div className="hidden lg:flex items-center space-x-4 xl:space-x-8 py-4">
        {/* {navbarData && (
          <p
            onClick={() => handleNavLinkClick("whatWeDo")}
            className="monstrrate text-[#000000] uppercase text-base cursor-pointer font-medium"
          >
            {navbarData.whatWeDo}
          </p>
        )}
        {navbarData && (
          <p
            onClick={() => handleNavLinkClick("topGames")}
            className="monstrrate text-[#000000] uppercase text-base cursor-pointer font-medium"
          >
            {navbarData.topGames}
          </p>
        )} */}

        {navbarData && (
          <p
            onClick={() => handleNavLinkClick("about")}
            className="monstrrate text-[#000000] uppercase text-base cursor-pointer font-medium"
          >
            {navbarData.aboutUs}
          </p>
        )}

        {navbarData && (
          <p
            onClick={() => handleNavLinkClick("games")}
            className=" monstrrate text-[#000000] uppercase text-base cursor-pointer font-medium"
          >
            {navbarData.teams}
          </p>
        )}
        {navbarData && pageData && (
          <Link
            to={`/${pageData.blog ? pageData.blog : "blog"}`}
            className="monstrrate text-[#000000] uppercase text-base cursor-pointer font-medium"
          >
            {navbarData.blog}
          </Link>
        )}
        {/* {navbarData && (
          <Link
            to={`/${
              pageData && pageData.reviews ? pageData.reviews : "reviews"
            }`}
            className="monstrrate text-[#000000] uppercase text-base cursor-pointer font-medium"
          >
            {navbarData.reviews}
          </Link>
        )} */}

        {navbarData && (
          <p
            onClick={() => handleNavLinkClick("contact")}
            className="monstrrate text-[#000000]  uppercase px-4 py-2 text-base font-medium p-3 rounded-sm cursor-pointer"
            style={{
              background: " linear-gradient(180deg, #7CFA73 0%, #21DBA4 100%)",
            }}
          >
            {navbarData.contact}
          </p>
        )}
      </div>
    </nav>
  );
}
