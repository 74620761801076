import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import client from "../../../sanityCli";
import imageUrlBuilder from "@sanity/image-url";
import "./HomeHero.css";
import img from './hero.svg'

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

export default function HomeHero(f_heroData) {
  const [heroData, setHeroData] = useState(f_heroData);
  const [navbarData, setNavbarData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const navbarResult = await client.fetch(
          '*[_type == "navbarContent"][0]'
        );
        setNavbarData(navbarResult);
      } catch (error) {
        console.error("Error fetching navbar data from Sanity:", error);
      }
    };
    fetchData();

    if (f_heroData !== heroData) {
      setHeroData(f_heroData);
    }
    window.scrollTo(0, 0);
  }, [f_heroData]);

  // const {
  //   backgroundImage,
  //   heroText,
  //   description,
  //   buttonText,
  //   buttonIcon,
  //   rightBackgroundImage,
  //   rightImage,
  //   socialMediaIcons,
  // } = heroData?.heroData;

  const location = useLocation();
  const navigate = useNavigate();

  const handleNavLinkClick = (sectionId) => {
    // console.log("section", sectionId);
    if (location.pathname !== "/") {
      // Navigate to the home page first
      navigate("/");
      // After navigating to home, scroll to the specified section
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 500); // Adjust the delay time as needed
    } else {
      // Scroll to the specified section with smooth behavior
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  return (
    // <div
   
    //   className="home-hero md:bg-cover md:bg-center lg:pt-6 pt-4 pb-2 lg:pb-0 md:pt-4 md:pb-0 pl-4 md:pl-10 lg:pl-[4.25vw] 2xl:pl-28 flex flex-col-reverse items-center justify-center md:flex-row md:items-center "
    //   style={{ backgroundImage: `url(${urlFor(backgroundImage)})` }}
    // >
    //   <div className="home-hero-left flex items-start flex-col gap-2 lg:gap-6 w-full lg:w-3/5 ">
    //     <h1 className="poppins text-[5vw] leading-[7vw] md:text-2xl lg:text-3xl xl:text-5xl 2xl:text-[63px]  xl:leading-[72px] 2xl-leading-[95px] font-bold text-gray-800 hidden md:block">
    //       {heroText}
    //     </h1>

    //     <p className=" poppins home-hero-description text-[4vw] leading-[6vw] pr-[3vw] md:text-[2vw] md:leading-[3vw] lg:text-2xl 2xl:text-[32px]  xl:leading-8 2xl:leading-10 text-gray-600">
    //       {description}
    //     </p>
    //       <div className="hidden md:flex items-center gap-1 bg-gradient-to-r from-yellow-500 to-yellow-300 rounded-2xl px-4 py-3 hover:scale-105 transition-all"  onClick={() => handleNavLinkClick("contact")}>
    //         <p className="text-base lg:text-lg font-medium">{buttonText}</p>
    //         {buttonIcon && <img src={urlFor(buttonIcon)} alt={buttonText} className="w-5"/>}
    //       </div>
    //       <div className="md:hidden flex items-center gap-1 bg-gradient-to-r from-yellow-500 to-yellow-300 rounded-xl px-4 my-3 py-3 hover:scale-105 transition-all"  onClick={() => handleNavLinkClick("contact")}>
    //         <p className="monstrrate text-base lg:text-lg font-medium">{buttonText}</p>
    //         {buttonIcon && <img src={urlFor(buttonIcon)} alt={buttonText} className="w-4"/>}
    //       </div>
    //   </div>
    //   <div
    //     className="home-hero-right w-full lg:w-2/5 relative lg:bg-right lg:bg-cover bg-contain bg-right  bg-no-repeat pb-6"
    //     style={{ backgroundImage: `url(${urlFor(rightBackgroundImage)})` }}
    //   >
    //     <h1 className="text-[5vw] pr-6 md:text-5x block md:hidden font-bold text-gray-800">
    //       {heroText}
    //     </h1>
    //     {rightImage && (
    //       <img
    //         src={urlFor(rightImage)}
    //         alt="rightImage"
    //         className="remote-img mt-1 md:mt-10 w-4/5 "
    //       />
    //     )}
    //     <div className="social-media-icon md:flex flex-col md:absolute bottom-10 right-5">
    //       {socialMediaIcons.map(({ link, image }, index) => (
    //         <Link key={index} to={link} target="_blank">
    //           {image && (
    //             <img
    //               src={urlFor(image)}
    //               alt={`social-icon-${index}`}
    //               className="w-9 h-9 mb-2 hidden md:block"
    //             />
    //           )}
    //         </Link>
    //       ))}
    //     </div>
    //   </div>
    // </div>
    <div className="w-full lg:h-screen">
        <img src={img} alt="hero-image" className="w-full"/>
    </div>
  );
}
