import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import imageUrlBuilder from "@sanity/image-url";
import client from "../../../sanityCli";
import FeaturesSection from "./FeaturesSection";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

export default function HomeAbout() {
  const [aboutData, setAboutData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await client.fetch('*[_type == "aboutUs"][0]');
        setAboutData(result);
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };

    fetchData();
  }, []);

  if (!aboutData) return null;

  const {
    header,
    subHeader,
    description,
    rightHeader,
    rightDescription,
    aboutImg,
    stats,
    socialMediaIcon,
  } = aboutData;

  return (
    <div className="lg:py-10 lg:px-20 py-8 px-2 lg:mt-[6%]" id="about">
      <div className="flex flex-col items-center gap-4">
        <p className="monstrrate text-[#179148] uppercase text-[3vw] lg:text-2xl font-semibold">
          {header}
        </p>
        <h1 className="monstrrate text-center font-bold text-[4.8vw] md:text-4xl lg:text-4xl 2xl:text-6xl text-gray-700 Montserrat uppercase tracking-wider">
          {subHeader}
        </h1>
        <h6 className="poppins text-center font-normal text-sm px-[1vw] md:text-xl lg:text-2xl 2xl:text-3xl text-gray-700 Poppins leading-relaxed tracking-wide xl:px-56">
          {description}
        </h6>
      </div>
      <FeaturesSection />
      <div className="w-full flex-col flex lg:flex-row items-center justify-center gap-8 lg:py-16">
        <div className="lg:w-1/2 w-full items-start flex justify-center">
          {aboutImg && <img src={urlFor(aboutImg)} alt="/" className="w-4/5"/>}
        </div>
        <div className="w-full flex flex-col items-center gap-4 lg:items-start lg:gap-6">
          <h1 className="monstrrate text-xl lg:text-3xl leading-[3vw] font-semibold mb-4">{rightHeader}</h1>
          <p className="poppins text-center lg:text-start text-slate-600 px-2 lg:px-0 lg:py-0 lg:text[1.25vw] lg:leading-[2vw] w-[90vw] md:w-full">
            {rightDescription}
          </p>
          {/* <div className="flex items-center justify-around lg:justify-start w-full lg:space-x-12 py-10">
            {socialMediaIcon.map((image, id) => (
              <Link key={id} to={image.iconUrl} target="_blank">
                <img
                  src={urlFor(image.icon)}
                  alt="/"
                  className="hover:scale-105 cursor-pointer"
                />
              </Link>
            ))}
          </div> */}
        </div>
      </div>
    </div>
  );
}
