import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import client from "../../../sanityCli"; // Import your Sanity client
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

function LatestBlog() {
  const [latestBlogData, setLatestBlogData] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    // Fetch data from Sanity
    client
      .fetch('*[_type == "latestBlog"][0]')
      .then((data) => setLatestBlogData(data))
      .catch(console.error);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === latestBlogData?.virtualCrouselBox.length - 1
          ? 0
          : prevIndex + 1
      );
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval); // Clear the interval on component unmount
  }, [latestBlogData]); // Only re-run effect if latestBlogData changes

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0
        ? latestBlogData.virtualCrouselBox.length - 1
        : prevIndex - 1
    );
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === latestBlogData.virtualCrouselBox.length - 1
        ? 0
        : prevIndex + 1
    );
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  if (!latestBlogData) {
    return <div></div>;
  }

  const {
    title,
    subTitle,
    description,
    virtualBox,
    smallBox_1,
    smallBox_2,
    virtualCrouselBox,
    leftIcon,
    rightIcon,
  } = latestBlogData;

  return (
    <div className="latest-blog my-[10%] mt-[25%] lg:my-10">
      <div className="latest-blog-heading flex flex-col items-center justify-center lg:gap-8 gap-4">
        <h2 className="monstrrate text-[#179148] uppercase text-xl font-semibold">
          {title}
        </h2>
        <h1 className="monstrrate text-center font-bold text-[5vw] md:text-4xl lg:text-4xl 2xl:text-6xl text-[#2d2d2d] Montserrat uppercase tracking-wider">
          {subTitle}
        </h1>
        <p className="poppins text-center font-normal text-[3.2vw] px-[5vw] lg:px-[12vw] xl:px-[15vw] md:text-xl lg:text-2xl xl:text-[1.8vw] xl:leading-[48px] text-[#2d2d2d] Poppins leading-relaxed tracking-wide">
          {description}
        </p>
      </div>
      <div
        className="virtual-reality-container h-[360px] md:h-[80vw] lg:h-[35vw] m-[5%] flex items-center gap-4 lg:p-12 md:m-4 lg:m-20 rounded-lg relative"
        style={{
          background:
            "linear-gradient(180deg, #7CFA73 0%, #21DBA4 100%)",
        }}
      >
        <div className="left-image-container w-1/2 absolute bottom-0 left-1 lg:block hidden">
          <img
            className="w-4/5"
            src={urlFor(virtualBox.virtualBoxImage).url()}
            alt="Virtual Box"
          />
        </div>
        <div className="lg:w-1/2 w-full absolute lg:right-16 right-0 flex flex-col md:items-start items-center space-y-8 lg:space-y-4 overflow-hidden">
          <h1 className="monstrrate text-gray-800 font-semibold text-[5.8vw] lg:pr-[2vw] xl:pr-[4vw] md:text-4xl lg:text-4xl xl:text-6xl text-center lg:text-start uppercase tracking-tight">
            {virtualBox.virtualBoxTitle.split("").map((letter, index) => (
              <span
                key={index}
                style={{
                  color: index >= 12 && index <= 19 ? "whitesmoke" : "",
                  // Change color based on screen size
                }}
              >
                {letter}
              </span>
            ))}
          </h1>
          <p className="poppins text-[#2d2d2d] font-normal text-[4vw] lg:text-[20px] xl:text-[24px] leading-snug tracking-wide font-poppins xl:pr-20 text-center lg:text-start px-4 md:px-0">
            {virtualBox.virtualBoxSubTitle}
          </p>
          <button className="monstrrate bg-gray-800 text-white py-2 px-4 rounded-2xl text-[3vw] lg:text-xl mx-auto lg:mx-0">
            <Link to={virtualBox.buttonLink}>
              {virtualBox.virtualBoxButttonText}
            </Link>
          </button>
        </div>
      </div>
      <div className="latest-blog-small-box flex items-start gap-4 m-4 xl:mx-20">
        <div className="w-1/4 relative md:block hidden">
          <Link to={smallBox_1.linkedBlog}>
            <img
              className="w-full lg:h-[30vw] h-[45vh] rounded-md"
              src={urlFor(smallBox_1.boxImage)}
              alt="Small Box 1"
            />
            <div className="flex flex-col gap-2 absolute bottom-2 left-2 right-2 rounded-lg bg-white bg-opacity-50 backdrop-[3px] border border-gray-300 p-1">
              <h1 className="roboto text-[#2d2d2d] text-lg lg:text-xl text-start">
                {smallBox_1.title}
              </h1>
              <p className="roboto text-start text-sm lg:text-lg text-gray-800">
                {smallBox_1.subTitle.slice(0, 60)}...
              </p>
            </div>
          </Link>
        </div>
        <div className="w-1/4 relative md:block hidden">
          <Link to={smallBox_2.linkedBlog}>
            <img
              className="w-full lg:h-[30vw] h-[45vh] rounded-md"
              src={urlFor(smallBox_2.boxImage)}
              alt="Small Box 2"
            />
            <div className="flex flex-col gap-2 absolute bottom-2 left-2 right-2 rounded-lg bg-white bg-opacity-50 backdrop-[3px] border border-gray-300 p-1">
              <h1 className="roboto text-gray-800 text-lg lg:text-xl text-start">
                {smallBox_2.title}
              </h1>
              <p className="roboto text-start text-sm lg:text-lg text-gray-800">
                {smallBox_2.subTitle.slice(0, 60)}...
              </p>
            </div>
          </Link>
        </div>
        <div className="latest-blog-crousel-card md:w-2/4 w-full relative overflow-hidden rounded-lg">
          <button
            className="absolute md:left-1 left-2 md:top-[180px] top-[160px] transform -translate-y-1/2 z-10 bg-slate-100 rounded-lg w-[8vw] h-[8vw] md:w-[3vw] md:h-[3vw] flex justify-center items-center"
            onClick={prevSlide}
          >
            {leftIcon && (
              <img
                src={urlFor(leftIcon)}
                alt="left Slide"
                className="w-8" // Adjust icon size as needed
              />
            )}
          </button>
          <button
            className="absolute md:right-1 right-2 md:top-[180px] top-[160px] transform -translate-y-1/2 z-10 bg-slate-100 rounded-lg text-xl font-semibold w-[8vw] h-[8vw] md:w-[3vw] md:h-[3vw] flex justify-center items-center"
            onClick={nextSlide}
          >
            {rightIcon && (
              <img
                src={urlFor(rightIcon)}
                alt="right Slide"
                className="w-8" // Adjust icon size as needed
              />
            )}
          </button>

          <div
            className="moving-container flex"
            style={{
              transition: "transform 0.5s ease",
              transform: `translateX(-${currentIndex * 100}%)`,
            }}
          >
            {/* Render carousel items dynamically */}
            {virtualCrouselBox.map((item, index) => (
              <Link
                to={item.linkedBlog}
                key={item.id}
                className="carousel-item flex-shrink-0 w-full relative"
              >
                <img
                  className="w-full lg:h-[30vw] h-[45vh] rounded-md bg-opacity-5"
                  src={urlFor(item.image)}
                  alt={item.title}
                />
                <div className="w-[96%] flex flex-col gap-2 absolute bottom-2 lg:left-3 left-2 rounded-lg bg-slate-50 bg-opacity-50 backdrop-[3px] border border-gray-300 px-2 py-4">
                  <h1 className="roboto text-gray-800 text-lg lg:text-xl text-start">
                    {item.title}
                  </h1>
                  <p className="roboto text-start text-sm lg:text-lg text-gray-800">
                    {item.subTitle.slice(0, 100)}...
                  </p>
                </div>
                <div className="absolute bottom-4 right-5 flex space-x-2">
                  {[...Array(virtualCrouselBox.length)].map((_, dotIndex) => (
                    <div
                      key={dotIndex}
                      className={`w-2 h-2 rounded bg-slate-100 ${
                        dotIndex === currentIndex
                          ? "bg-white-300"
                          : "bg-slate-300"
                      } ${dotIndex === currentIndex ? "w-5 h-2" : ""}`}
                      onClick={() => handleDotClick(dotIndex)}
                    ></div>
                  ))}
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LatestBlog;
