import { FaLinkedin, FaTwitter, FaInstagram, FaFacebook, FaYoutube } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-black text-white py-8 px-6">
      <div className="max-w-[1280px] mx-auto flex flex-col md:flex-row justify-between items-center">
        
        {/* Left Side - Logo & Tagline */}
        <div className="text-center md:text-left">
          <h2 className="text-2xl font-bold flex items-center gap-2">
            <span className="text-4xl">V</span> VARY GAMING
          </h2>
          <p className="text-sm mt-1">Dare to vary!!!</p>
        </div>

        {/* Right Side - Contact & Social Links */}
        <div className="flex flex-col items-center md:items-end">
          <h3 className="text-lg font-semibold">Contact</h3>
          <p className="text-sm mt-1">📧 business@indicarena.com</p>
          <div className="flex gap-3 mt-2">
            <FaLinkedin className="text-xl hover:text-gray-400 cursor-pointer" />
            <FaTwitter className="text-xl hover:text-gray-400 cursor-pointer" />
            <FaInstagram className="text-xl hover:text-gray-400 cursor-pointer" />
            <FaFacebook className="text-xl hover:text-gray-400 cursor-pointer" />
            <FaYoutube className="text-xl hover:text-gray-400 cursor-pointer" />
          </div>
        </div>

      </div>

      {/* Bottom Section */}
      <div className="border-t border-gray-700 mt-6 pt-4 flex flex-col md:flex-row items-center justify-between">
        <p className="text-sm">Copyright © 2023 All Rights Reserved</p>
        <button className="bg-green-500 hover:bg-green-600 text-black font-semibold px-4 py-2 rounded-full flex items-center gap-2 mt-2 md:mt-0">
          🚀 Made By Indic Lancers
        </button>
      </div>
    </footer>
  );
};

export default Footer;





// import React, { useState, useEffect } from "react";
// import {Link, useLocation, useNavigate} from 'react-router-dom';
// import client from "../../../sanityCli";
// import "./Footer.css";
// import imageUrlBuilder from "@sanity/image-url";

// // Function to build image URLs
// const builder = imageUrlBuilder(client);
// function urlFor(source) {
//   return builder.image(source);
// }

// export default function Footer() {
//   const [footerData, setFooterData] = useState(null);
//   const [pageData, setPageData] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);



  
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const data = await client.fetch('*[_type == "footer"][0]');
//         setFooterData(data);
//       } catch (error) {
//         console.error("Error fetching data from Sanity:", error);
//         setError(error.message);
//       } finally {
//         setLoading(false);
//       }
//     };
//     const fetchPageData = async () => {
//       try {
//         const result = await client.fetch('*[_type == "pageContent"][0]');
//         setPageData(result);
//         // console.log("pageNavData", result);
//       } catch (error) {
//         console.error("Error fetching data from Sanity:", error);
//       }
//     };
//     fetchPageData();

//     fetchData();

//     window.scrollTo(0, 0)
//   }, []);


//   const location = useLocation();
//   const navigate = useNavigate();

//   const handleNavLinkClick = (sectionId) => {
//     // console.log("section", sectionId);
//     if (location.pathname !== "/") {
//       // Navigate to the home page first
//       navigate("/");
//       // After navigating to home, scroll to the specified section
//       setTimeout(() => {
//         const element = document.getElementById(sectionId);
//         if (element) {
//           element.scrollIntoView({ behavior: "smooth" });
//         }
//       }, 500); // Adjust the delay time as needed
//     } else {
//       // Scroll to the specified section with smooth behavior
//       const element = document.getElementById(sectionId);
//       if (element) {
//         element.scrollIntoView({ behavior: "smooth" });
//       }
//     }
//   };

//   if (loading) return <div></div>;
//   if (error) return <div>Error: {error}</div>;
//   if (!footerData) return null;


//   const { companySection, contactSection, socialMediaSection, contactTitle, socialMedia } =
//     footerData;

//   return (
//     <>
//       {/* Desktop Footer */}
//       <div className="footer-container bg-black hidden md:block">
//         <div className="footer-link">
//           <div className="footer-sec-1">
//             {companySection.logo && (  
//               <img src={urlFor(companySection.logo)} alt="/" onClick={() => handleNavLinkClick('home-hero')} className="cursor-pointer"/>
//             )}
//             <p>{companySection.description}</p>
//             <p>{companySection.copyright}</p>
//             {companySection.indicLogo && (
//               <Link to={companySection.link} target="blanck"><img src={urlFor(companySection.indicLogo)} alt="/" /></Link>
//             )}
//           </div>
//           <div className="footer-sec-2">
//           {footerData && <h1 className="poppins text-[1.25vw] leading-[2vw] font-bold capitalize">{footerData.companySection.companyTitle}</h1>}
//             {footerData  && <span className="cursor-pointer text-[1vw] leading-[1.25vw] font-normal poppins text-slate-300" onClick={() => handleNavLinkClick("contact")}>{footerData.companySection.company1}</span>}
//             {footerData && pageData && <Link to={`/${pageData.reviews ? pageData.reviews :'reviews' }`} className="cursor-pointer text-[1vw] leading-[1.25vw] font-normal poppins text-slate-300">{footerData.companySection.company2}</Link>}
//             {footerData && pageData && <Link to={`/${pageData.ratingScale ? pageData.ratingScale :'scalerating' }`} className="cursor-pointer text-[1vw] leading-[1.25vw] font-normal poppins text-slate-300">{footerData.companySection.company3}</Link>}
//           </div>
//           <div className="footer-sec-3">
//            {footerData && <h1 className="poppins text-[1.25vw] leading-[2vw] font-bold capitalize">{footerData.legalSection.legalTitle}</h1>}
//             {footerData && pageData && <Link to={`/${pageData.privacyPolicy ? pageData.privacyPolicy:'privacyPolicy' }`} className="cursor-pointer text-[1vw] leading-[1.25vw] font-normal poppins text-slate-300" >{footerData.legalSection.termsCondition}</Link>}
//             {footerData && pageData && <Link to={`/${pageData.privacyPolicy ? pageData.privacyPolicy:'privacyPolicy' }`} className="cursor-pointer text-[1vw] leading-[1.25vw] font-normal poppins text-slate-300">{footerData.legalSection.privacyPolicy}</Link>}
//             {footerData && pageData && <Link to={`/${pageData.cookiesPolicy ? pageData.cookiesPolicy:'cookiesPolicy' }`} className="cursor-pointer text-[1vw] leading-[1.25vw] font-normal poppins text-slate-300">{footerData.legalSection.cookiesPolicy}</Link>}
        
//           </div>
//           <div className="footer-sec-4">
//             <h1 className="poppins text-[1.25vw] leading-[2vw] font-bold capitalize">{contactTitle}</h1>
//             {contactSection.items.map((item, index) => (
//               <span key={index}>
//                 {item.icon && <img src={urlFor(item.icon)} alt="icon" />}
//                 <p>
//                   <a href={item.url} className="cursor-pointer text-[1vw] leading-[1.25vw] font-normal poppins text-slate-300">{item.text}</a>
//                 </p>
//               </span>
//             ))}
//           </div>
//           <div className="footer-sec-5">
//             <h1 className="poppins text-[1.25vw] leading-[2vw] font-bold capitalize">{socialMedia}</h1>
//             <div>
//               {socialMediaSection.items.map((item, index) => (
//                 <a key={index} href={item.url}>
//                   {item.icon && <img src={urlFor(item.icon)} alt="/" />}
//                 </a>
//               ))}
//             </div>
//           </div>
//         </div>
//         <span className="footer-line"></span>
//         <p className="footer-text">{companySection.copyright}</p>
//       </div>

//       {/* Mobile Footer */}
//       <div className="parent block md:hidden w-full bg-black py-10 px-4">
//         <div className="flex flex-col w-full space-y-4">
//           {companySection.logo && (
//             <img src={urlFor(companySection.logo)} alt="/" className="w-40" />
//           )}
//           <p className="text-gray-300 text-lg font-normal">
//             {companySection.description}
//           </p>
//           <p className="text-gray-200 text-lg">{companySection.copyright}</p>
//         </div>
//         <div className="flex items-start justify-between my-8">
//           <div className="flex flex-col items-start space-y-5 text-white">
//             {footerData && <h1 className="poppins text-slate-200 text-xl font-medium capitalize ">{footerData.companySection.companyTitle}</h1>}
//             {footerData  && <span className="cursor-pointer font-light text-[12px] leading-[15px] poppins text-slate-300" onClick={() => handleNavLinkClick("contact")}>{footerData.companySection.company1}</span>}
//             {footerData && pageData && <Link to={`/${pageData.reviews ? pageData.reviews :'reviews' }`} className="cursor-pointer font-light text-[12px] leading-[15px] poppins text-slate-300">{footerData.companySection.company2}</Link>}
//             {footerData && pageData && <Link to={`/${pageData.ratingScale ? pageData.ratingScale :'scalerating' }`} className="cursor-pointer font-light text-[12px] leading-[15px] poppins text-slate-300">{footerData.companySection.company3}</Link>}

//           </div>
//           <div className="flex flex-col items-start space-y-5 text-white">
            
//             {footerData && <h1 className="poppins text-slate-200 text-xl font-medium capitalize">{footerData.legalSection.legalTitle}</h1>}
//             {footerData && pageData && <Link to={`/${pageData.privacyPolicy ? pageData.privacyPolicy:'privacyPolicy' }`} className="cursor-pointer font-light text-[12px] leading-[15px] poppins text-slate-300">{footerData.legalSection.termsCondition}</Link>}
//             {footerData && pageData && <Link to={`/${pageData.privacyPolicy ? pageData.privacyPolicy:'privacyPolicy' }`} className="cursor-pointer font-light text-[12px] leading-[15px] poppins text-slate-300">{footerData.legalSection.privacyPolicy}</Link>}
//             {footerData && pageData && <Link to={`/${pageData.cookiesPolicy ? pageData.cookiesPolicy:'cookiesPolicy' }`} className="cursor-pointer font-light text-[12px] leading-[15px] poppins text-slate-300">{footerData.legalSection.cookiesPolicy}</Link>}
//           </div>
//         </div>
//         <div className="flex flex-col items-start space-y-5">
//           <h1 className="poppins text-slate-200 text-xl font-medium capitalize mb-4">{contactTitle}</h1>
//          {contactSection.items.map((item, index) => (
//             <span key={index} className="flex items-center gap-2">
//               {item.icon && <img src={urlFor(item.icon)} alt="icon" />}
//               <p className="text-base text-center text-white">
//                 <a href={item.url} className="cursor-pointer font-light text-[12px] leading-[15px] poppins text-slate-300">{item.text}</a>
//               </p>
//             </span>
//           ))} 
//         </div>
//         {companySection.indicLogo && (
//           <Link to={companySection.link} target="blanck"><img src={urlFor(companySection.indicLogo)} alt="/" className="w-48 my-4 mt-4" /></Link>
//         )}
//         <div className="flex items-start flex-col space-y-5">
//           <h1 className="hidden md:block text-white text-xl font-medium">{socialMedia}</h1>
//           <div className="flex items-start gap-3 mt-2">
//             {socialMediaSection.items.map((item, index) => (
//               <a key={index} href={item.url}>
//                 {item.icon && <img src={urlFor(item.icon)} alt="/" />}
//               </a>
//             ))}
//           </div>
//           <span className="md:hidden text-white text-sm poppins mt-5">© 2023 All rights reserved</span>
//         </div>
//       </div>
//     </>
//   );
// }

