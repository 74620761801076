import React, { useState, useEffect } from "react";
import HomeHero from "./homeSec1/HomeHero";
import HomeHeroBottom from "./homeSec1/HomeHeroBottom";
import OurServices from "./homeSec2/OurServices";
import HomeAbout from "./homeSec4/HomeAbout";
import HomeOurTeam from "./homeSec5/HomeOurTeam";
import HomeFaq from "./homeSec6/HomeFaq";
import HomeConsultant from "./homeSec7/HomeConsultant";
import client from "../../sanityCli";
import LatestBlog from "./homeSec3.2/LatestBlog";
import Navbar from "../navbar/Navbar";
import Footer from "./homeSec9/Footer";
import CardsGames from "./homeSec3.1/CardsGames";
import Games from "./games/Games";
// import Loader from "./Loader"; // Import your loader component

export default function HomeComponents() {
  // const [heroData, setHeroData] = useState(null);
  const [faqData, setFaqData] = useState(null);
  const [loading, setLoading] = useState(true); // State to track loading status

  useEffect(() => {
    let passedValue = localStorage.getItem("myData");
    if (passedValue !== "true") {
      window.location.reload();
      localStorage.setItem("myData", "true");
    }
    const fetchData = async () => {
      try {
        // const result = await client.fetch(`*[_type == "homeHero"][0]`);
        // setHeroData(result);

        const faqResult = await client.fetch(`*[_type == "faq"][0]`);
        setFaqData(faqResult);

        // Once data is fetched, set loading to false
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };

    fetchData();
  }, []);

  // Render loading indicator while data is being fetched
  if (loading) {
    return <div></div>;
  }

  // Render components once data is fetched
  return (
    <div className="overflow-hidden">
      <Navbar />
      {/* {heroData && <HomeHero heroData={heroData} />} */}
      <HomeHero />
      {/* <HomeHeroBottom /> */}
      {/* <OurServices /> */}
      {/* <CardsGames /> */}
      
      <HomeAbout />
      <HomeOurTeam />
      <Games />
      {/* {faqData && <HomeFaq faqData={faqData} />} */}
      {/* <HomeConsultant /> */}
      <br /><br /><br />
      <LatestBlog />
      <br /><br /><br />
      <Footer />
    </div>
  );
}
