import React from "react";
import { Monitor, Wrench, Globe } from "lucide-react"; // Using Lucide for icons

const FeaturesSection = () => {
  const features = [
    {
      icon: <Monitor size={48} className="text-[#179148]" />,
      title: "Cross-Platform",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
    },
    {
      icon: <Wrench size={48} className="text-[#179148]" />,
      title: "Live Ops Tech",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
    },
    {
      icon: <Globe size={48} className="text-[#179148]"   />,
      title: "Global Self-Publishing",
      description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt",
    },
  ];

  return (
    <section className="py-12 bg-white">
      <div className="max-w-6xl mx-auto px-6 md:px-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-20 text-center">
          {features.map((feature, index) => (
            <div key={index} className="flex flex-col items-center">
              {feature.icon}
              <h3 className="text-lg font-semibold text-[#179148] mt-4">{feature.title}</h3>
              <p className="text-gray-800 mt-2 max-w-xs">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
